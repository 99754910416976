<!-------------------------------------------------
description:  通用业务组件 通用列表
/**
 *@author xiaosanye 
 *@date 2023/2/25
*/
--------------------------------------------------->
<script>
  import YkcTable from '@/components/ykc-ui/table/src/table.vue';
  import YkcDialog from '@/components/ykc-ui/dialog';

  export default {
    name: 'CustomList',
    data() {
      return {
        tableData: [],
        pageInfo: {
          total: 0,
          pageIndex: 1,
          pageSize: 10,
        },
      };
    },
    components: {
      YkcTable,
    },
    props: {
      // 温馨提示文字
      warmTips: {
        type: String,
        required: false,
        default: '',
      },
      // 是否显示表格标题
      showHeader: {
        type: Boolean,
        default: true,
      },
      // 表单搜索的配置项
      searchData: {
        type: Array,
        // Default empty array
        default: () => [],
      },
      // 页面顶部按钮
      pageTopButtons: {
        type: Array,
        // Default empty array
        default: () => [],
        required: false,
      },
      // 是否展示顶部按钮
      showTopButtons: {
        type: Boolean,
        default: false,
      },
      // 搜索项字段配置
      searchParams: {
        type: Object,
        // Default empty object
        default: () => ({}),
      },
      // 表格配置
      tableConfig: {
        type: Object,
        // Default empty object
        default: () => ({}),
      },
      // 表格栏配置
      tableColumns: {
        type: Array,
        // Default empty array
        default: () => [],
      },
      // 表格操作按钮组
      tableOperateButtons: {
        type: Array,
        // Default empty array
        default: () => [],
      },
      // 表格标题
      tableTitle: {
        type: String,
        // Default table title
        default: () => '默认标题',
      },
      // 头部按钮配置
      headerButtons: {
        type: Array,
        // Default empty array
        default: () => [],
      },
      // 表格选择列配置
      selectTable: {
        type: Boolean,
        // Default set to false
        default: () => false,
      },
      // 在多选表格中，当仅有部分行被选中时，点击表头的多选框时的行为。若为 true，则选中所有行；若为 false，则取消选择所有行
      indeterminate: {
        type: Boolean,
        // Default set to false
        default: () => true,
      },
      selectionChange: {
        type: Function,
        default: () => {},
      },
      // 是否在created 立即调用
      requestCall: {
        type: Boolean,
        // Default set to false
        default: () => true,
      },
      // 请求列表参数
      requestMethod: {
        type: Function,
        // Default returns a resolved promise
        default: () => Promise.resolve({ records: Array.from({ length: 0 }), total: 0 }),
      },
      // 是否是纯粹的表格，默认不是，纯粹表格使用 div 包裹，非纯粹表格使用 scroll-layout 包裹
      isPure: {
        type: Boolean,
        // Default set to false
        default: () => false,
      },
      // 搜索参数验证keys array
      searchParamsValidateKeys: {
        type: Array,
        default: () => [],
      },
      // 搜索验证错误信息
      searchParamValidateErrMsg: {
        type: String,
      },
      // 搜索按钮文本
      searchBtnText: {
        type: String,
        default: '确认',
      },
      // 重置按钮文本
      resetBtnText: {
        type: String,
        default: '清空',
      },
      //   根节点的样式
      rootStyle: {
        type: Object,
        default: () => ({}),
      },
      // 操作栏固定方式
      operateFixedType: {
        type: String,
        default: 'auto',
      },
    },
    created() {
      if (this.requestCall) {
        this.searchTableList();
      }
    },
    methods: {
      validateRequiredSearchKeys() {
        // validate keys
        let valid = true;
        // eslint-disable-next-line no-restricted-syntax
        for (const key of this.$props.searchParamsValidateKeys) {
          if (!this.searchParams[key]) {
            valid = false;
          }
        }
        if (valid) {
          this.pageInfo.pageIndex = 1;
          this.searchTableList();
        } else {
          YkcDialog({
            dialogType: 'feedback',
            showTitle: false,
            showFooter: true,
            showCancelButton: false,
            desc: this.$props.searchParamValidateErrMsg,
            onCancel: done => {
              done();
            },
            onConfirm: done => {
              done();
            },
          });
        }
      },
      calcBodyHeight() {
        // console.log('calc body height');
      },
      searchTableList() {
        const reqParamsOfDeny = {
          ...this.searchParams,
          current: this.pageInfo.pageIndex,
          size: this.pageInfo.pageSize,
        };
        this.requestMethod(reqParamsOfDeny)
          .then(response => {
            console.log('-> response', response);
            this.tableData = response?.records || [];
            this.pageInfo.total = response?.total || 0;
          })
          .catch(err => {
            console.error(err);
          });
      },
      /** 分页 change 事件监听器 */
      handlePaginationCurrentChange(current) {
        this.pageInfo.pageIndex = current;
        this.searchTableList();
      },
      /** 分页 size  change  事件监听器 */
      handlePaginationSizeChange(size) {
        this.pageInfo.pageIndex = 1;
        this.pageInfo.pageSize = size;
        this.searchTableList();
      },
      /** 点击清空查询按钮 */
      handleSearchClean(form) {
        // Object.assign(this.searchParams, form);
        this.$emit('clear-form', form);
      },

      /** 点击查询按钮 */
      handleSearchConfirm(form) {
        // Object.assign(this.searchParams, form);
        this.$emit('query-form', form);
        if (this.$props.searchParamsValidateKeys.length) {
          this.validateRequiredSearchKeys();
          return;
        }
        this.pageInfo.pageIndex = 1;
        this.searchTableList();
      },
      topHeaderButtonRender() {
        const buttons = this.$props.pageTopButtons;
        if (buttons.length) {
          return buttons.map((item, index) => {
            return (
              <ykc-button
                key={item.id || index}
                {...{
                  props: { ...item },
                }}
                onClick={() => item.handleClick(item, this)}>
                {item.text}
              </ykc-button>
            );
          });
        }
        return null;
      },
      slotHeaderRender() {
        if (this.$props.showHeader) {
          const warmTips = this.$props.warmTips ? (
            <ykc-warm-tip type="warning" description={this.$props.warmTips} />
          ) : null;
          const topButtons = this.$props.showTopButtons ? this.topHeaderButtonRender() : null;
          return (
            <template slot="header">
              {warmTips}
              {topButtons}
              {this.$slots.headers}
              <ykc-search
                ref="YkcSearch"
                {...{
                  props: {
                    data: this.$props.searchData,
                    searchForm: this.$props.searchParams,
                    confirmBtnLabel: this.$props.searchBtnText,
                    clearBtnLabel: this.$props.resetBtnText,
                  },
                }}
                onClick={this.handleSearchConfirm.bind(this)}
                onClean={this.handleSearchClean.bind(this)}></ykc-search>
            </template>
          );
        }
        return null;
      },
      headerButtonsRender() {
        if (this.$props.headerButtons.length) {
          return (
            <template slot="headerButtons">
              <div className="header-buttons-wrap">
                <div>
                  {this.$props.headerButtons.map(item => (
                    <ykc-button
                      className="header-btn-item"
                      type="plain"
                      onClick={() => item.handle(item, this)}>
                      {item.label}
                    </ykc-button>
                  ))}
                </div>
              </div>
            </template>
          );
        }
        return null;
      },
      tableContentRender() {
        return (
          <div className="custom-list-main-content">
            <ykc-table
              ref="YkcTable"
              data={this.tableData}
              operateFixedType={this.$props.operateFixedType}
              tableConfig={this.$props.tableConfig}
              columns={this.$props.tableColumns}
              title={this.$props.tableTitle}
              headerButtons={this.$props.headerButtons}
              selectable={this.$props.selectTable}
              selectOnIndeterminate={this.$props.indeterminate}
              operateButtons={this.$props.tableOperateButtons}
              {...{
                on: {
                  'selection-change': (...val) => this.$props.selectionChange(...val),
                },
              }}>
              {this.headerButtonsRender()}
              <ykc-pagination
                slot="pagination"
                ref="YkcPagination"
                direct="flex-end"
                total={this.pageInfo.total}
                currentPage={this.pageInfo.pageIndex}
                pageSize={this.pageInfo.pageSize}
                {...{
                  on: {
                    'update:currentPage': val => {
                      this.pageInfo.pageIndex = val;
                    },
                    'update:pageSize': val => {
                      this.pageInfo.pageSize = val;
                    },
                    'current-change': this.handlePaginationCurrentChange,
                    'size-change': this.handlePaginationSizeChange,
                  },
                }}></ykc-pagination>
            </ykc-table>
          </div>
        );
      },
    },
    render() {
      return (
        <div
          class="custom-list"
          {...{
            class: {
              'pure-table': this.isPure,
            },
            style: {
              ...this.rootStyle,
            },
          }}>
          {/** 通过this.$slots.default定义默认插槽 */}
          {this.isPure ? (
            <div class="pure-table">{this.tableContentRender()}</div>
          ) : (
            <scroll-layout
              {...{
                on: {
                  'scrollbar-update': () => this.calcBodyHeight(),
                },
              }}>
              {this.slotHeaderRender()}
              {this.tableContentRender()}
              {this.$slots.footer}
            </scroll-layout>
          )}
        </div>
      );
    },
  };
</script>

<style scoped lang="scss">
  .custom-list {
    height: calc(100vh - 73px);
  }
  .custom-list.pure-table {
    height: calc(50vh - 10px);
  }
  .header-buttons-wrap {
    display: flex;
    flex-direction: row;
    .header-btn-item {
      margin-left: 8px;
      &:first-child {
        margin-left: 0;
      }
    }
  }
  .ykc-button + .ykc-button {
    margin-left: 8px;
  }
</style>
